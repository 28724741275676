/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updateHighScore = /* GraphQL */ `
  mutation UpdateHighScore($highScore: Int, $publishHighScore: Boolean) {
    updateHighScore(
      highScore: $highScore
      publishHighScore: $publishHighScore
    ) {
      cognitoUsername
      sso_id
      id
      first_name
      last_name
      email
      pid
      committee_member_committees {
        id
        unit_id
        name
        type
        committee_member_id
        manager
      }
      titles {
        id
        name
        unit_id
        unit_name
        title_association_id
        can_be_deleted
      }
      departments {
        id
        name
        ancestry_depth
        ancestor_institution_id
        parent_unit_id
        child_unit_ids
      }
      appointmentPermission
      lastCaseKickoffLogin
      highScore
      publishHighScore
      createdAt
      updatedAt
    }
  }
`;
export const createReviewCommittee = /* GraphQL */ `
  mutation CreateReviewCommittee($packetId: Int, $rankCodes: String) {
    createReviewCommittee(packetId: $packetId, rankCodes: $rankCodes) {
      status
    }
  }
`;
export const omitUnsubmittedCommitteeForms = /* GraphQL */ `
  mutation OmitUnsubmittedCommitteeForms(
    $packetId: Int
    $workflowStepId: Int
    $committeeId: Int
  ) {
    omitUnsubmittedCommitteeForms(
      packetId: $packetId
      workflowStepId: $workflowStepId
      committeeId: $committeeId
    ) {
      status
    }
  }
`;
export const deleteInterfolioUser = /* GraphQL */ `
  mutation DeleteInterfolioUser($input: DeleteInterfolioUserInput!) {
    deleteInterfolioUser(input: $input) {
      cognitoUsername
      sso_id
      id
      first_name
      last_name
      email
      pid
      committee_member_committees {
        id
        unit_id
        name
        type
        committee_member_id
        manager
      }
      titles {
        id
        name
        unit_id
        unit_name
        title_association_id
        can_be_deleted
      }
      departments {
        id
        name
        ancestry_depth
        ancestor_institution_id
        parent_unit_id
        child_unit_ids
      }
      appointmentPermission
      lastCaseKickoffLogin
      highScore
      publishHighScore
      createdAt
      updatedAt
    }
  }
`;
export const createCaseKickoff = /* GraphQL */ `
  mutation CreateCaseKickoff($input: CreateCaseKickoffInput!) {
    createCaseKickoff(input: $input) {
      id
      data {
        candidateEmail
        candidateFirstName
        candidateLastName
        candidatePennId
        candidatePennkey
        interfolioCaseId
        adminOrg
        division
        startTerm
        endTerm
        caseAction
        title
        positionDesc
        lps
        lpsUnit
        businessOffice
        langInstructor
        overrideReapp
        proposedSal
        fundingSource
        fundingNotes
        approvedBudgetAmt
        budgetFiscalYear
        budgetFundCode
        cref
        allCourseNotes
        courses {
          row
          subjectArea
          courseNum
          courseTerm
          numSecs
          avgEnroll
        }
        departmentName
        hasFunding
        hasCourses
        visaRequired
        visaType
        rankStartDate
        startDate
        endDate
        creatorUsername
        facultyCommitteeRankCodes
        degreeCompleted
        degreeCompletedDate
        citizenship
      }
      status
      interfolioId
      warnings {
        message
        detail
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCaseKickoff = /* GraphQL */ `
  mutation DeleteCaseKickoff($input: DeleteCaseKickoffInput!) {
    deleteCaseKickoff(input: $input) {
      id
      data {
        candidateEmail
        candidateFirstName
        candidateLastName
        candidatePennId
        candidatePennkey
        interfolioCaseId
        adminOrg
        division
        startTerm
        endTerm
        caseAction
        title
        positionDesc
        lps
        lpsUnit
        businessOffice
        langInstructor
        overrideReapp
        proposedSal
        fundingSource
        fundingNotes
        approvedBudgetAmt
        budgetFiscalYear
        budgetFundCode
        cref
        allCourseNotes
        courses {
          row
          subjectArea
          courseNum
          courseTerm
          numSecs
          avgEnroll
        }
        departmentName
        hasFunding
        hasCourses
        visaRequired
        visaType
        rankStartDate
        startDate
        endDate
        creatorUsername
        facultyCommitteeRankCodes
        degreeCompleted
        degreeCompletedDate
        citizenship
      }
      status
      interfolioId
      warnings {
        message
        detail
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createInterfolioUser = /* GraphQL */ `
  mutation CreateInterfolioUser($input: CreateInterfolioUserInput!) {
    createInterfolioUser(input: $input) {
      cognitoUsername
      sso_id
      id
      first_name
      last_name
      email
      pid
      committee_member_committees {
        id
        unit_id
        name
        type
        committee_member_id
        manager
      }
      titles {
        id
        name
        unit_id
        unit_name
        title_association_id
        can_be_deleted
      }
      departments {
        id
        name
        ancestry_depth
        ancestor_institution_id
        parent_unit_id
        child_unit_ids
      }
      appointmentPermission
      lastCaseKickoffLogin
      highScore
      publishHighScore
      createdAt
      updatedAt
    }
  }
`;
export const updateInterfolioUser = /* GraphQL */ `
  mutation UpdateInterfolioUser($input: UpdateInterfolioUserInput!) {
    updateInterfolioUser(input: $input) {
      cognitoUsername
      sso_id
      id
      first_name
      last_name
      email
      pid
      committee_member_committees {
        id
        unit_id
        name
        type
        committee_member_id
        manager
      }
      titles {
        id
        name
        unit_id
        unit_name
        title_association_id
        can_be_deleted
      }
      departments {
        id
        name
        ancestry_depth
        ancestor_institution_id
        parent_unit_id
        child_unit_ids
      }
      appointmentPermission
      lastCaseKickoffLogin
      highScore
      publishHighScore
      createdAt
      updatedAt
    }
  }
`;
export const updateCaseKickoff = /* GraphQL */ `
  mutation UpdateCaseKickoff($input: UpdateCaseKickoffInput!) {
    updateCaseKickoff(input: $input) {
      id
      data {
        candidateEmail
        candidateFirstName
        candidateLastName
        candidatePennId
        candidatePennkey
        interfolioCaseId
        adminOrg
        division
        startTerm
        endTerm
        caseAction
        title
        positionDesc
        lps
        lpsUnit
        businessOffice
        langInstructor
        overrideReapp
        proposedSal
        fundingSource
        fundingNotes
        approvedBudgetAmt
        budgetFiscalYear
        budgetFundCode
        cref
        allCourseNotes
        courses {
          row
          subjectArea
          courseNum
          courseTerm
          numSecs
          avgEnroll
        }
        departmentName
        hasFunding
        hasCourses
        visaRequired
        visaType
        rankStartDate
        startDate
        endDate
        creatorUsername
        facultyCommitteeRankCodes
        degreeCompleted
        degreeCompletedDate
        citizenship
      }
      status
      interfolioId
      warnings {
        message
        detail
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
