import {FUNDING_SOURCE_TYPES} from "./store-utils";

function emptyToNull(str) {
    if(!str) return null;
    if(str.length > 0) {
        return str;
    }
    return null;
}
function convertToDesiredDateFormat(date) {
  if(!date) return date;
  if(typeof date === "string") {
      let newDate = new Date(date);
      return newDate.toISOString();
  }
  if(parseInt(date) > 0) {
      let newDate = new Date(parseInt(date));
      return newDate.toISOString();
  }
  else if(typeof date === "object") {
      let newDate = date;
      return newDate.toISOString();
  }
  return null;
}
export default {
    namespaced: true,
    state: {
        'candidateStep': {},
        'positionStep': {},
        'fundingStep': {},
        'coursesStep': {},
        'savePoint': 'first'
    },
    mutations: {
        updateStep(state, data) {
            var step = data.step;
            state[step] = data;
            state.savePoint = data.savePoint;
        },
        reset(state) {
            state.candidateStep = {};
            state.positionStep = {};
            state.fundingStep = {};
            state.coursesStep = {};
            state.savePoint = 'first';
        },
        saveForm(state) {
            localStorage.setItem('caseKickoffForm', JSON.stringify(state));
        },
        loadForm(state) {
            if (localStorage.getItem('caseKickoffForm')) {
                var savedForm = JSON.parse(localStorage.getItem('caseKickoffForm'));
                state.candidateStep = savedForm.candidateStep;
                state.positionStep = savedForm.positionStep;
                state.fundingStep = savedForm.fundingStep;
                state.coursesStep = savedForm.coursesStep;
                state.savePoint = savedForm.savePoint;
            }
        }
    },
    actions: {
        updateStep({commit, dispatch}, data) {
            if (data.step == 'candidateStep') {
                dispatch('sasData/loadPositionHistory', { workerPennId: data.candidatePennId }, {root: true});
            }
            commit('updateStep', data);
        },
        initiateFromReappointment({commit, rootGetters}, reappointment) {
            commit('reset');
            let candidateStep = {
                step: "candidateStep",
                candidate: reappointment.pennkey,
                candidateEmail: reappointment.email,
                candidateFirstName: reappointment.firstName,
                candidateLastName: reappointment.lastName,
                candidatePennId: reappointment.pennId,
                candidatePennkey: reappointment.pennkey,
                interfolioCase: null
            };
            commit('updateStep', candidateStep);

            //get the appropriate title/action based upon years of service
            let title = null;
            let titleAction = rootGetters['title/titleActionFromTitleActionYears']({
                title: reappointment.interfolioJobTitle,
                action: 'Reappointment',
                years: reappointment.yearsAtRank
            });
            if(titleAction) {
                title = titleAction.title;
                if(titleAction.specialCase) {
                    title = title + ' - ' + titleAction.specialCase;
                }
            }
            let positionStep = {
                step: 'positionStep',
                adminOrg: reappointment.interfolioUnitId,
                startTerm: reappointment.nextTerm,
                endTerm: reappointment.defaultEndTerm,
                caseAction: 'Reappointment',
                title: title
            };
            commit('updateStep', positionStep);
        },
        initiateFromCPS({commit}, courses) {
            commit('reset');
            if(courses[0].pennId) {
                let candidateStep = {
                    step: "candidateStep",
                    candidate: courses[0].pennkey,
                    candidateEmail: courses[0].email,
                    candidateFirstName: courses[0].firstName,
                    candidateLastName: courses[0].lastName,
                    candidatePennId: courses[0].pennId,
                    candidatePennkey: courses[0].pennkey,
                    interfolioCase: null
                };
                commit('updateStep', candidateStep);
            }

            let positionStep = {
                step: 'positionStep',
                lps: true,
                adminOrg: courses[0].interfolioUnitId,
                startTerm: courses[0].termCode,
                endTerm: courses[0].termCode
            };
            commit('updateStep', positionStep);


            let stepCourses = [];
            let row = 0;
            for(let course of courses) {
                stepCourses.push({
                    row: row++,
                    subjectArea: course.subjectArea,
                    courseNum: course.courseNumber,
                    courseTerm: course.termCode,
                    numSecs: course.numSections,
                    avgEnroll: ""
                });
            }
            let coursesStep = {
                step: "coursesStep",
                allCourseNotes: "",
                courses: stepCourses
            };
            commit('updateStep', coursesStep);
        },
        saveForm({commit}) {
            commit('saveForm');
        },
        loadForm({commit}) {
            commit('loadForm');
        },
        clearForm({commit}) {
            commit('reset');
        }
    },
    getters: {
        stepData: (state) => (step) => {
            return state[step];
        },
        formData: state => {
            return state;
        },
        savePoint: state => {
            return state.savePoint
        },
        caseKickoffData: (state, getters, rootState, rootGetters) => {
            let data = {
                candidateEmail: state.candidateStep.candidateEmail,
                candidateFirstName: state.candidateStep.candidateFirstName,
                candidateLastName: state.candidateStep.candidateLastName,
                candidatePennId: emptyToNull(state.candidateStep.candidatePennId),
                candidatePennkey: emptyToNull(state.candidateStep.candidatePennkey),
                adminOrg: state.positionStep.adminOrg,
                caseAction: state.positionStep.caseAction,
                division: state.positionStep.division,
                endTerm: emptyToNull(state.positionStep.endTerm),
                langInstructor: state.positionStep.langInstructor,
                lps: state.positionStep.lps,
                positionDesc: emptyToNull(state.positionStep.positionDesc),
                overrideReapp: state.positionStep.hasOwnProperty("overrideReapp") ? state.positionStep.overrideReapp : false,
                startTerm: emptyToNull(state.positionStep.startTerm),
                title: state.positionStep.title,
                approvedBudgetAmt: emptyToNull(state.fundingStep.approvedBudgetAmt),
                budgetFiscalYear: emptyToNull(state.fundingStep.budgetFiscalYear),
                budgetFundCode: emptyToNull(state.fundingStep.budgetFundCode),
                cref: emptyToNull(state.fundingStep.cref),
                fundingNotes: emptyToNull(state.fundingStep.fundingNotes),
                fundingSource: state.fundingStep.fundingSource ? FUNDING_SOURCE_TYPES[state.fundingStep.fundingSource] : null,
                proposedSal: emptyToNull(state.fundingStep.proposedSal),
                allCourseNotes: emptyToNull(state.coursesStep.allCourseNotes),
                courses: emptyToNull(state.coursesStep.courses),
                hasCourses: state.positionStep.hasCourses,
                hasFunding: state.positionStep.hasFunding,
                visaRequired: state.positionStep.visaRequired,
                visaType: emptyToNull(state.positionStep.visaType),
                rankStartDate: convertToDesiredDateFormat(emptyToNull(state.positionStep.rankStartDate)),
                startDate: convertToDesiredDateFormat(emptyToNull(state.positionStep.startDate)),
                endDate: convertToDesiredDateFormat(emptyToNull(state.positionStep.endDate)),
                degreeCompletedDate: convertToDesiredDateFormat(emptyToNull(state.positionStep.degreeCompletedDate)),
                degreeCompleted: emptyToNull(state.positionStep.degreeCompleted),
                citizenship: emptyToNull(state.positionStep.citizenship),
                creatorUsername: rootGetters["user/username"],
                departmentName: rootGetters["interfolio/unit"](state.positionStep.adminOrg).name,
                facultyCommitteeRankCodes: emptyToNull(state.positionStep.facultyCommitteeRankCodes)
            };
            if(state.candidateStep.interfolioCase) {
                data.interfolioCaseId = state.candidateStep.interfolioCase.id;
            }
            let unit = rootGetters['sasData/getUnit'](state.positionStep.adminOrg);
            data.lpsUnit = unit.lpsUnit ? true: false;
            data.businessOffice = unit.businessOffice;
            return data;
        }
    }
}
